import React, { useState } from 'react'
import { Row, Col, Carousel, CarouselItem } from 'reactstrap'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import StaticMap, { Marker } from 'react-map-gl'

import Main from '~components/layouts/Main'
import { HTMLContent } from '~components/Content'
import scssVars from '~components/scss-variables'
import MapPin from '~components/Shared/MapPin'
import { Breadcrumbs } from '~components/Services/Intro'
import SEO from '~components/seo'
import Layout from '~components/Layout'

const SideStrip = styled.div`
  position: relative;
  margin-top: -3.5rem;
  display: inline-block;
  background-color: ${scssVars.gray};
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    background-color: ${scssVars.gray};
  }
  h1 {
    letter-spacing: 1.4px;
    font-size: 21px;
    line-height: 1.25;
    font-weight: 300;
    color: ${scssVars.darkBrown};
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: unset;
    margin-left: -1rem;
    width: 110%;
    z-index: 1;
    h1 {
      font-size: 36px;
    }
  }
`

const Breadbox = styled.div`
  padding: 20px 0 10px 0;
  ${Breadcrumbs} {
    margin-left: 0;
    a {
      color: ${scssVars.darkBrown};
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`

const LocationContent = styled.div`
  h2 {
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: -0.0125em;
    font-weight: 600;
    color: ${scssVars.darkBrown};
  }
  ul {
    padding-left: 20px;
  }
  p, li {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    color: ${scssVars.darkBrown};
  }
  hr {
    border-color: ${scssVars.darkBrown};
    margin: 25px 0;
  }
`

const StyledCarouselWrapper = styled.div`
  position: absolute;
  height: 100%;
  max-height: 85vh;
  width: 50%;
  right: 0;
  top: 0;
  .slide, .carousel-inner {
    height: 100%;
    width: 100%;
  }
  .slide {
    opacity: 1;
  }
`

const StyledCarouselItem = styled(CarouselItem)`
  position: relative;
  opacity: 1;
  height: 100%;
  width: 100%;
  .read-more {
    margin-top: 37px;
    a {
      display: block;
      line-height: 50px;
      padding: 0;
      font-size: 18px;
      color: white;
      font-weight: 500;
    }
  }
  span {
    vertical-align: text-top;
    line-height: 31px;
    font-size: 19px;
    position: absolute;
    color: white;
    font-weight: 100;
  }
`

const SubwayIcon = styled.img`
  height: 16px;
  width: auto;
  margin-right: 2px;
  vertical-align: sub;
`

const CarouselControlContainer = styled.div`
  @media only screen and (min-width: ${scssVars.mobileMin}) {
    position: absolute;
    top: 5%;
    display: flex;
    align-self: flex-end;
    height: 45px;
    width: 100%;
    z-index: 600;
    .arrow-path {
      fill:none;
      stroke: white;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-width:2px;
    }
    .carousel-control {
      width: 30px;
      opacity: 1;
      filter: drop-shadow(0px 0px 1px rgba(0,0,0, 0.5));
    }
    .carousel-control-prev {
      left: 75%;
    }
    .carousel-control-next {
      left: 85%;
    }
  }
`

const CarouselImage = styled.div`
  height: 100%;
  width: 100%;
  background: url(${(p) => p.img}) center / cover;
`

const SemiFluidContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const ImageCarousel = ({ items }) => {
  const [index, setIndex] = useState(0)
  let animating = false
  const next = () => {
    if (animating) return
    const nextIndex = index === items.length - 1 ? 0 : index + 1
    setIndex(nextIndex)
  }
  const previous = () => {
    if (animating) return
    const nextIndex = index === 0 ? items.length - 1 : index - 1
    setIndex(nextIndex)
  }
  return (
    <StyledCarouselWrapper>
      <Carousel
        activeIndex={index}
        next={next}
        previous={previous}
      >
        {items.map((item) => (
          <StyledCarouselItem
            onExiting={() => { animating = true }}
            onExited={() => { animating = false }}
            key={item.altText}
          >
            <CarouselImage img={item.src} />
          </StyledCarouselItem>
        ))}
        <CarouselControlContainer>
          <a className="carousel-control carousel-control-prev" role="button" onClick={previous}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999">
              <path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(-22240.004 -12677.111) rotate(180)"/>
            </svg>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control carousel-control-next" role="button" onClick={next}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999">
              <path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(22257 12709.11)"/>
            </svg>
            <span className="sr-only">Next</span>
          </a>
        </CarouselControlContainer>
      </Carousel>
    </StyledCarouselWrapper>
  )
}

ImageCarousel.propTypes = {
  items: PropTypes.array
}

const LocationPage = ({ data: { markdownRemark } }) => {
  const {
    html,
    frontmatter: {
      title,
      path,
      images,
      longitude,
      latitude,
      trains,
      pins
    }
  } = markdownRemark

  const Map = () => (
    <StaticMap
      width={'100%'}
      height={400}
      latitude={latitude}
      longitude={longitude}
      zoom={13}
      scrollZoom={false}
      doubleClickZoom={false}
      mapStyle={process.env.MAPBOX_STYLE}
      mapboxApiAccessToken={process.env.MAPBOX_TOKEN}
    >
      <Marker longitude={longitude} latitude={latitude}>
        <MapPin size={20} pins={pins} />
      </Marker>
    </StaticMap>
  )

  const SubwaySection = () => (
    <>
      <h2>Nearest Transit Stations</h2>
      <ul>
        {trains.map((train, i) => (
          <li key={`train-${i}`}>
            {train.name}&nbsp;&nbsp;
            {train.lines.map((line, j) => (
              <SubwayIcon key={`line-${j}`} src={`https://leatherspa.imgix.net/metro-icons/${line}.svg`} alt={line.toUpperCase()} />
            ))}
          </li>
        ))}
      </ul>
    </>
  )

  return (
    <Layout>
      <SEO title={title} pathname={path} />
      <Main>
        <div className="d-lg-none">
          <div className="container-fluid px-0">
            <img className="w-100" src={images[0].src} alt={title}/>
            <SideStrip className="p-3">
              <h1 className="m-0">{title}</h1>
            </SideStrip>
            <div className="p-3">
              <Link to="/locations">&laquo; Locations</Link>
            </div>
          </div>
          <LocationContent className="pt-3 container-fluid">
            <HTMLContent content={html} />
            <SubwaySection />
          </LocationContent>
        </div>
        <div className="d-none d-lg-block">
          <SemiFluidContainerWrapper>
            <div className="container">
              <Row>
                <Col lg={{ size: 6 }}>
                  <Breadbox>
                    <Breadcrumbs>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/locations">Locations</Link>
                      </li>
                      <li>{title}</li>
                    </Breadcrumbs>
                  </Breadbox>
                  <SideStrip className="pl-3 py-4">
                    <h1 className="m-0">{title}</h1>
                  </SideStrip>
                  <LocationContent className="py-5 pr-5">
                    <HTMLContent className="" content={html} />
                    <SubwaySection />
                  </LocationContent>
                </Col>
              </Row>
              <ImageCarousel items={images}/>
            </div>
          </SemiFluidContainerWrapper>
        </div>
        <Map/>
      </Main>
    </Layout>
  )
}

LocationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string,
        content: PropTypes.string,
        address: PropTypes.string,
        longitude: PropTypes.number,
        latitude: PropTypes.number,
        trains: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          lines: PropTypes.array.isRequired
        })),
        images: PropTypes.array,
        pins: PropTypes.array
      })
    })
  })
}

export default LocationPage

export const locationPageQuery = graphql`
  query LocationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        address
        longitude
        latitude
        pins
        trains {
          name
          lines
        }
        images {
          src
          altText
        }
      }
    }
  }
`
